import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList() {
  const toast = useToast()
  const refFreelancersListTable = ref(null)

  const tableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Naam', key: 'fullName', sortable: true },
    { label: 'Telefoonnummer', key: 'phone', sortable: true },
    { label: 'E-mailadres', key: 'email', sortable: true },
    { label: 'Openstaand bedrag', key: 'openAmount', sortable: true },
    { label: 'Acties', key: 'actions', sortable: false },
  ]

  const tableInvoicesColumns = [
    { label: 'Factuurnummer', key: 'id', sortable: true },
    { label: 'Datum', key: 'createTimestamp', sortable: true },
    { label: 'verloningsperiode', key: 'paycheckPeriod', sortable: true },
    { label: 'Beschrijving', key: 'description', sortable: true },
    { label: 'Bedrag', key: 'totalAmount', sortable: true },
    { label: 'Status', key: 'status', sortable: true },
    { label: 'Acties', key: 'actions', sortable: false },
  ]

  const tableAgreementsColumns = [
    { label: 'Naam', key: 'name', sortable: true },
    { label: 'Startdatum', key: 'startDate', sortable: true },
    { label: 'Eindatum', key: 'endDate', sortable: true },
    { label: 'Status', key: 'status', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]

  const tableIdentificationFilesColumns = [
    { label: 'Document', key: 'name', sortable: false },
    { label: 'Status', key: 'status', sortable: false },
    { label: 'Acties', key: 'actions', sortable: false },
  ]

  const perPage = ref(50)
  const total = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(false)
  const searchQuery = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refFreelancersListTable.value ? refFreelancersListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const refetchData = () => {
    refFreelancersListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchFreelancers = (ctx, callback) => {
    showOverlay.value = true
    store
      .dispatch('app-freelancer/fetchFreelancers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        total.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ophalen freelancers',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
          },
        })
      })
  }

  const resolveTypeVariant = status => {
    if (status === 1) return { status: 'Nieuw', variant: 'warning' }
    if (status === 10) return { status: 'Controle Payrite', variant: 'warning' }
    if (status === 11) return { status: 'Controle Freelancer', variant: 'warning' }
    if (status === 12) return { status: 'Controle Opdrachtgever', variant: 'warning' }
    if (status === 20) return { status: 'Open', variant: 'warning' }
    if (status === 50) return { status: 'Afgekeurd', variant: 'danger' }
    if (status === 51) return { status: 'Afgekeurd', variant: 'danger' }
    if (status === 90) return { status: 'Betaald', variant: 'success' }
    return { tooltip: 'Verstuurd', icon: 'CreditCardIcon', variant: 'warning' }
  }

  const sectorOptions = [
    { name: 'Zorg, gezondheid, geestelijke en maatschappelijke belangen', value: 2 },
    { name: 'Sport en recreatie', value: 3 },
    { name: 'Horeca', value: 4 },
    { name: 'Zakelijke dienstverlening', value: 5 },
    { name: 'Media en journalistiek', value: 6 },
    { name: 'Culturele instellingen', value: 7 },
    { name: 'Overheid', value: 8 },
    { name: 'Schoonmaakbedrijf', value: 9 },
    { name: 'Bakkerijen en slagersbedrijf', value: 10 },
    { name: 'Grootwinkelbedrijf', value: 11 },
    { name: 'Overige detailhandel en ambachten', value: 1 },
    { name: 'Scheepvaart en havenbedrijven', value: 13 },
    { name: 'Goederenvervoer', value: 14 },
    { name: 'Personenvervoer', value: 15 },
    { name: 'Overige transport', value: 16 },
    { name: 'Agrarisch bedrijf', value: 17 },
    { name: 'Stukadoor en schildersbedrijf', value: 18 },
    { name: 'Dakdekkersbedrijf', value: 19 },
    { name: 'Overig bouwbedrijf', value: 20 },
    { name: 'Chemische industrie', value: 21 },
    { name: 'Voedingsindustrie', value: 22 },
    { name: 'Zuivelindustrie', value: 23 },
    { name: 'Metaal en (elektro) technische bedrijfstakken', value: 24 },
    { name: 'Overige industrie', value: 25 },
    { name: 'Overige takken van bedrijf en beroep', value: 26 },
  ]

  const noticePeriods = [
    { label: 'Geen', value: 1 },
    { label: '1 maand', value: 2 },
    { label: '2 maanden', value: 3 },
  ]

  const agreementUnits = [
    { label: 'Uur', value: 'Uur' },
    { label: 'Dienst', value: 'Dienst' },
    { label: 'Bezorging', value: 'Bezorging' },
  ]

  const resolveTypeAgreementVariant = status => {
    if (status === 5) return { status: 'Wachten op freelancer', variant: 'warning' }
    if (status === 6) return { status: 'Wachten op organisatie', variant: 'warning' }
    if (status === 10) return { status: 'Getekent', variant: 'success' }
    if (status === 11) return { status: 'Afgewezen', variant: 'danger' }
    if (status === 20) return { status: 'Verlopen', variant: 'danger' }
    return { tooltip: '', variant: '' }
  }

  const resolveIdStatusVariant = status => {
    if (status === 1) return { status: 'Verified', variant: 'success' }
    return { status: 'Not verified', variant: 'warning' }
  }

  return {
    tableColumns,
    perPage,
    total,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    showOverlay,
    searchQuery,
    dataMeta,
    refFreelancersListTable,
    currentPage,
    tableInvoicesColumns,
    tableAgreementsColumns,
    fetchFreelancers,
    refetchData,
    resolveTypeVariant,
    sectorOptions,
    noticePeriods,
    agreementUnits,
    resolveTypeAgreementVariant,
    tableIdentificationFilesColumns,
    resolveIdStatusVariant,
  }
}
